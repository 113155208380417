<template>
  <!-- <article class="page-contents-wrap">
    <sub-title :text="'알 잔고'" />
    <div class="page-content">
      <div class="item-row-container">
        <div class="item-wrap">
          <div class="item-title">알 한도</div>
          <div class="item-content">
            <div class="onlyNum">
              <input type="number" id="maxCreditInput" v-model="regInfo.maxCredit" />
              <label for="maxCreditInput">{{ numberWithCommas(regInfo.maxCredit) }}</label>
            </div>
          </div>
        </div>
        <div class="item-wrap">
          <div class="item-title">수수료율(%)</div>
          <div class="item-content">
            <select v-model="regInfo.creditRate">
              <template v-for="(n, index) in Number(creditRate) + 1" :key="index">
                <option>{{ index }}</option>
              </template>
            </select>
          </div>
        </div>
      </div>
    </div>
  </article> -->
  <article class="page-contents-wrap">
    <sub-title :text="'게임별 알 수수료'">
      <div>
        <button type="button" class="btn-layout btn-blue mr-5" @click="rateAllChange('Y')">전체 ON</button>
              <button type="button" class="btn-layout btn-red mr-5" @click="rateAllChange('N')">전체 OFF</button>
        <button type="button" class="btn-layout btn-grey mr-5" title="새로고침" @click="refreshData">
          <i class="fas fa-redo"></i>
        </button>
        <button type="button" class="btn-layout btn-blue" @click="saveToggles">{{ $t('button.save') }}</button>
      </div>
    </sub-title>
    <div class="page-content s-content-wrap wrapper-contents itemz">
      <template v-for="item in rateList" :key="item.vendorCode">
        <div class="s-content">
          <div class="title">
            <span>{{ item.vendorName }}</span>
          </div>
          <div class="content">
            <div class="toggle-wrap rollcount">
              <span class="toggle_text" :class="item.betBool ? 'on' : 'off'"></span>
              <input type="checkbox" class="set-switch" v-model="item.betBool" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </article>
</template>
<script>
import lodash from "lodash";
import subTitle from "@/components/main/PageSubTitle";
import { getSiteData } from "@/libs/auth-helper";
import { companyDetail } from "@/api/member";
import { compGamesBetModify } from "@/api/company";
export default {
  name: "RateSetting",
  components: {
    subTitle
  },
  data() {
    return {
      memberDetail: {},
      rateList: []
    };
  },
  methods: {
    async setPtRates() {
      console.log(this.memberDetail);
      const req = {
        siteId: this.memberDetail.memId,
        rateList: []
      };
      for (const item of this.rateList) {
        const model = {
          gameType: item.gameType,
          pointRate: item.pointRate
        };
        req.rateList.push(model);
      }
      const res = await compGamesRateModify(req);
      console.log(res);
      if (res.resultCode === "0") {
        alert("설정완료");
        await this.refreshData();
      } else {
        alert("설정실패");
      }
    },
    async refreshData() {
      try {
        await this.setGameList();
        this.setRateList();
      } catch (e) {
        console.error(e);
      } finally {
        // this.scontentSetting();
      }
    },
    rateAllChange(type) {
      const rateInfo = this.rateList;
      for (const item of rateInfo) {
        item.compBetYn = type;
        if(type==='Y'){
          item.betBool = true;
        }else{
          item.betBool = false;
        }
      }
    },
    async getUpperCompany() {
      const reqDetail = {
        siteId: this.$route.params.id,
        memId: this.$route.params.id
      };
      const loginCompanyDetail = await companyDetail(reqDetail);

      return await loginCompanyDetail.data.memberDetail;
    },
    setRateList() {},
    setToggles() {
      const list = this.rateList
      for (const item of list) {
        console.log(item.compBetYn);
        if (item.compBetYn === "Y") {
          item.betBool = true;
        } else {
          item.betBool = false;
        }
      }
      this.rateList = list;
    },
    async saveToggles(){
      for (const item of this.rateList) {
        console.log(item.compBetYn);
        if (item.betBool) {
          item.compBetYn = "Y";
        } else {
          item.compBetYn = "N";;
        }
      }
      const req = {
        siteId: this.memberDetail.memId,
        biRateList: []
      };
      for (const item of this.rateList) {
        const model = {
          gameType: item.gameType,
          vendorCode: item.vendorCode,
          compBetYn: item.compBetYn
        };
        req.biRateList.push(model);
      }
      const res = await compGamesBetModify(req);
      console.log(res);
      if (res.resultCode === "0") {
        alert("설정완료");
        await this.refreshData();
      } else {
        alert("설정실패");
      }
    }
  },
  async created() {
    this.memberDetail = await this.getUpperCompany();
    this.rateList = this.memberDetail.BI_SubPointRate;
    this.setToggles();
    await this.setGameList();
    this.setRateList();
  }
};
</script>
<style scoped>
body {
  height: 100vh;
}
.page-contents-wrap {
  margin-bottom: 0;
  height: 100%;
}
.s-content > .content {
  display: flex;
  font-size: 1em;
  height: calc(56px - 26px);
}

.s-content-wrap {
  max-height: calc(100% - 64px);
  overflow-y: auto;
}

.s-content > .title {
  margin-bottom: 0;
  background: #18698b;
  white-space: nowrap;
  color: #fff;
  border-radius: 6px 6px 0 0;
}

.itemz .s-content {
  max-width: unset;
  width: 135px;
  flex: 0 0 auto;
  flex-basis: unset;
  border: 0;
}

.itemz .s-content .content select {
  border-top: 0;
  border-radius: 0 0 6px 6px;
  height: 30px;
  text-align: center;
}

.rollcount {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #9e9e9e;
  padding: 0 30px;
  border-top: 0;
  border-radius: 0 0 6px 6px;
}

.rollcount button {
  color: #676767;
  font-weight: bold;
  font-size: 1.2em;
  background: #fff;
}

.rollcount span {
  font-weight: bold;
  color: #212121;
  font-size: 1em;
}

.center button {
  margin: 10px 0 70px;
  font-size: 14px;
  width: 130px;
  height: 45px;
}

.center {
  text-align: center;
}

.rollcount > input[type="number"] {
  width: 45px;
  text-align: center;
  border: none;
}

.onlyNum {
  position: relative;
}

#maxCreditInput {
  background-color: transparent;
  color: transparent;
  position: relative;
  z-index: 1;
}
#maxCreditInput::selection {
  background: none;
}
#maxCreditInput + label {
  background: #fff;
  font-weight: 500;
  border: 1px solid #18698b;
  border-radius: 3px;
  height: 26px;
  padding: 0 6px;
  box-sizing: border-box;
  width: 100%;
  max-width: 190px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sub-title {
  padding: 5px 10px;
}
.sub-title input[type="number"] {
  width: 80px;
}
</style>
